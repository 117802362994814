const getRandomId = (prefix) => prefix + Math.floor(Math.random() * 1000);

const initalState = {
  loading: true,
  alerts: {
    message: "",
    variant: "",
    errorLocation: "",
  },
  name: "",
  isActive: true,
  primary: true,
  form_id: 0,
  fb_sections: [
    {
      section_id: getRandomId("section-"),
      section_title: "",
      fields: [],
      isOpen: true,
   
    },
  ],
};

const FormBuilderReducer = (state = initalState, action) => {
  switch (action.type) {
    case "update-state":
      return {
        ...state,
        ...action.state,
      };

    case "add-section":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections,
          {
            section_id: getRandomId("section-"),
            section_title: "",
            fields: [],
            isOpen: true,
          },
        ],
      };
    case "remove-section":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter(
            (section) => section.section_id !== action.sectionId
          ),
        ],
      };
    case "change-section-name":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.sectionId) {
              section.section_title = action.sectionName;
            }
            return section;
          }),
        ],
      };
    case "add-field":
      return addFormField(state, action);

    case "delete-field":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter(
                  (field) => field.field_id !== action.params.fieldId
                ),
              ];
            }
            return section;
          }),
        ],
      };
    case "update-field-position":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.section.section_id) {
              section.fields = [...action.section.fields];
            }
            return section;
          }),
        ],
      };

    case "change-field-value":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter((field) => {
                  if (field.field_id === action.params.fieldId) {
                    console.log(action.params.value);
                    field[action.params.input] = action.params.value;
                  }
                  return field;
                }),
              ];
            }
            return section;
          }),
        ],
      };

    case "change-field-require":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter((field) => {
                  if (field.field_id === action.params.fieldId) {
                    field.isRequired = !field.isRequired;
                  }
                  return field;
                }),
              ];
            }
            return section;
          }),
        ],
      };
    case "change-field-readmore":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter((field) => {
                  if (field.field_id === action.params.fieldId) {
                    field.hasReadMore = !field.hasReadMore;
                  }
                  return field;
                }),
              ];
            }
            return section;
          }),
        ],
      };
    case "delete-option":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter((field) => {
                  if (field.field_id === action.params.fieldId) {
                    field.options = [
                      ...field.options.filter(
                        (option) => option.option_id !== action.params.optionId
                      ),
                    ];
                  }
                  return field;
                }),
              ];
            }
            return section;
          }),
        ],
      };

    case "change-option-value":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter((field) => {
                  if (field.field_id === action.params.fieldId) {
                    field.options = [
                      ...field.options.filter((option) => {
                        if (option.option_id === action.params.optionId) {
                          option[action.params.field] = action.params.value;
                        }
                        return option;
                      }),
                    ];
                  }
                  return field;
                }),
              ];
            }
            return section;
          }),
        ],
      };

    case "add-option":
      return {
        ...state,
        fb_sections: [
          ...state.fb_sections.filter((section) => {
            if (section.section_id === action.params.sectionId) {
              section.fields = [
                ...section.fields.filter((field) => {
                  if (field.field_id === action.params.fieldId) {
                    field.options = [
                      ...field.options,
                      {
                        option_id: getRandomId("option-"),
                        value: "",
                        suggestion: "",
                      },
                    ];
                  }
                  return field;
                }),
              ];
            }
            return section;
          }),
        ],
      };
    default:
      return state;
  }
};

const addFormField = (state, action) => {
  let field = {
    field_id: getRandomId("field-"),
    isRequired: true,
    question: "",
    answer: "",
    reasonfield: "",
    field: action.field,
    options: [],
    hasReadMore: false,
    readMoreText: "",
    isOpen: true,
    details: "",
    recommendation: "",
    risks: "",
    weight: 0,
    severity: 0,
    difficulty: "",
    failureTitle:"",
  };

  if (
    action.field === "Selectbox" ||
    action.field === "CheckBox" ||
    action.field === "RadioButtons" ||
    action.field === "SelectboxWithTextArea"
  ) {
    field.options = [
      {
        option_id: getRandomId("option-"),
        value: "",
        suggestion: "",
      },
      {
        option_id: getRandomId("option-"),
        value: "",
        suggestion: "",
      },
      {
        option_id: getRandomId("option-"),
        value: "",
        suggestion: "",
      },
    ];
  }

  if (action.field === "SelectboxWithTextArea") {
    field.hasInput = true;
  }

  if (action.field === "DocumentUpload") {
    field.docType = "ID";
  }

  return {
    ...state,
    fb_sections: [
      ...state.fb_sections.filter((section) => {
        if (section.section_id === action.sectionId) {
          section.fields = [...section.fields, field];
        }
        return section;
      }),
    ],
  };
};
export default FormBuilderReducer;
