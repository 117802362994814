import { SAVE_USER, LOGOUT } from './types';

export const saveUser = (user) => ({
    type: SAVE_USER,
    user
});

export const logout = (user) => ({
    type: LOGOUT,
    user
});