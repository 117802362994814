import { SAVE_USER, LOGOUT } from '../actions/types';

const initState = JSON.parse(localStorage.getItem('user'))

const sessionReducer = (state, action) => {
    if(!state) {return initState;}
    switch(action.type) {
        case SAVE_USER:
            return {
                ...state,
                ...action.user
            };
        case LOGOUT:
            localStorage.removeItem('user');
            localStorage.removeItem('userToken');
            window.location.href = '/login';
            return initState;
        default:
            return state;
    }
};

export default sessionReducer;