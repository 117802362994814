import { SET_LOADING ,SHOW_POPUP} from './types';

export const setLoading = (loading) => ({
    type: SET_LOADING,
    loading
});
export const setPopup = (showpopup) => ({
    type: SHOW_POPUP,
    showpopup:showpopup
});
