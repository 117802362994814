import { combineReducers } from 'redux';
import common from './common';
import session from './session';
import FormListReducer from './forms-list';
import FormBuilderReducer from './FormBuilderReducer';

export default combineReducers({
    common,
    session,
    formBuilder: FormBuilderReducer,
    forms: FormListReducer
});