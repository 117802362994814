import { SET_LOADING,SHOW_POPUP } from '../actions/types';

const initState = {
    loading: false,
    demo:123,
    showpopup:false
};

const common = (state, action) => {
    if(!state) {
        return initState;
    }

    switch(action.type) {
        case SHOW_POPUP:
            return {
                ...state,
                showpopup: action.showpopup
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
}

export default common;